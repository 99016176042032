@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;900&display=swap");

@font-face {
  font-family: "Roboto";
  src: url(./fonts/Roboto-Regular.ttf) format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Roboto";
  src: url(./fonts/Roboto-Bold.ttf) format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Roboto";
  src: url(./fonts/Roboto-Bold.ttf) format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Noto Sans KR";
  src: url(./fonts/NotoSansKR-Regular.otf) format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "Noto Sans KR";
  src: url(./fonts/NotoSansKR-Regular.otf) format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "Noto Sans KR";
  src: url(./fonts/NotoSansKR-Bold.otf) format("opentype");
  font-weight: 600;
}

@font-face {
  font-family: "Noto Sans KR";
  src: url(./fonts/NotoSansKR-Bold.otf) format("opentype");
  font-weight: 700;
}

body {
  margin: 0;
  font-family: "Roboto", "Noto Sans KR";
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.mapboxgl-popup-tip {
  border: none !important;
}

.mapboxgl-popup-close-button {
  display: none;
}

.mapboxgl-popup-content {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}
