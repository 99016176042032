.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
  background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1507_7878)'%3E%3Cpath d='M26 21H21V26C21 26.55 20.55 27 20 27C19.45 27 19 26.55 19 26V21H14C13.45 21 13 20.55 13 20C13 19.45 13.45 19 14 19H19V14C19 13.45 19.45 13 20 13C20.55 13 21 13.45 21 14V19H26C26.55 19 27 19.45 27 20C27 20.55 26.55 21 26 21Z' fill='%2316B5B8'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1507_7878'%3E%3Crect width='24' height='24' fill='white' transform='translate(8 8)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
  background-image: url("data:image/svg+xml,%3Csvg width='40' height='39' viewBox='0 0 40 39' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M25.0003 21.8337H15.0003C14.542 21.8337 14.167 21.4587 14.167 21.0003C14.167 20.542 14.542 20.167 15.0003 20.167H25.0003C25.4587 20.167 25.8337 20.542 25.8337 21.0003C25.8337 21.4587 25.4587 21.8337 25.0003 21.8337Z' fill='%2316B5B8'/%3E%3Cline x1='1' y1='0.5' x2='46' y2='0.5' stroke='%23A5ABB3'/%3E%3C/svg%3E%0A");
}

.mapboxgl-ctrl button.mapboxgl-ctrl-geolocate .mapboxgl-ctrl-icon {
  background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.9998 7.52153C9.07805 7.52153 7.52153 9.07805 7.52153 10.9998C7.52153 12.9215 9.07805 14.478 10.9998 14.478C12.9215 14.478 14.478 12.9215 14.478 10.9998C14.478 9.07805 12.9215 7.52153 10.9998 7.52153ZM18.7737 10.1302C18.3737 6.50413 15.4954 3.62587 11.8694 3.22587V2.30414C11.8694 1.82587 11.478 1.43457 10.9998 1.43457C10.5215 1.43457 10.1302 1.82587 10.1302 2.30414V3.22587C6.50413 3.62587 3.62587 6.50413 3.22587 10.1302H2.30414C1.82587 10.1302 1.43457 10.5215 1.43457 10.9998C1.43457 11.478 1.82587 11.8694 2.30414 11.8694H3.22587C3.62587 15.4954 6.50413 18.3737 10.1302 18.7737V19.6954C10.1302 20.1737 10.5215 20.565 10.9998 20.565C11.478 20.565 11.8694 20.1737 11.8694 19.6954V18.7737C15.4954 18.3737 18.3737 15.4954 18.7737 11.8694H19.6954C20.1737 11.8694 20.565 11.478 20.565 10.9998C20.565 10.5215 20.1737 10.1302 19.6954 10.1302H18.7737ZM10.9998 17.0867C7.63457 17.0867 4.91283 14.365 4.91283 10.9998C4.91283 7.63457 7.63457 4.91283 10.9998 4.91283C14.365 4.91283 17.0867 7.63457 17.0867 10.9998C17.0867 14.365 14.365 17.0867 10.9998 17.0867Z' fill='%23A5ABB3'/%3E%3C/svg%3E%0A");
}

.mapboxgl-ctrl-group button {
  height: 40px;
  width: 40px;
}

.mapbox-gl-draw_polygon {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 7V3.25C22 2.55977 21.4402 2 20.75 2H17C16.3098 2 15.75 2.55977 15.75 3.25H8.25C8.25 2.55977 7.69023 2 7 2H3.25C2.55977 2 2 2.55977 2 3.25V7C2 7.69023 2.55977 8.25 3.25 8.25V15.75C2.55977 15.75 2 16.3098 2 17V20.75C2 21.4402 2.55977 22 3.25 22H7C7.69023 22 8.25 21.4402 8.25 20.75H15.75C15.75 21.4402 16.3098 22 17 22H20.75C21.4402 22 22 21.4402 22 20.75V17C22 16.3098 21.4402 15.75 20.75 15.75V8.25C21.4402 8.25 22 7.69023 22 7ZM18.25 4.5H19.5V5.75H18.25V4.5ZM4.5 4.5H5.75V5.75H4.5V4.5ZM5.75 19.5H4.5V18.25H5.75V19.5ZM19.5 19.5H18.25V18.25H19.5V19.5ZM18.25 15.75H17C16.3098 15.75 15.75 16.3098 15.75 17V18.25H8.25V17C8.25 16.3098 7.69023 15.75 7 15.75H5.75V8.25H7C7.69023 8.25 8.25 7.69023 8.25 7V5.75H15.75V7C15.75 7.69023 16.3098 8.25 17 8.25H18.25V15.75Z' fill='%23A5ABB3'/%3E%3C/svg%3E%0A");
  background-position: 50%;
  background-repeat: no-repeat;
  display: block;
  height: 100%;
  width: 100%;
}

.mapbox-gl-draw_polygon.active,
.mapbox-gl-draw_polygon:hover {
  background-color: inherit;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 7V3.25C22 2.55977 21.4402 2 20.75 2H17C16.3098 2 15.75 2.55977 15.75 3.25H8.25C8.25 2.55977 7.69023 2 7 2H3.25C2.55977 2 2 2.55977 2 3.25V7C2 7.69023 2.55977 8.25 3.25 8.25V15.75C2.55977 15.75 2 16.3098 2 17V20.75C2 21.4402 2.55977 22 3.25 22H7C7.69023 22 8.25 21.4402 8.25 20.75H15.75C15.75 21.4402 16.3098 22 17 22H20.75C21.4402 22 22 21.4402 22 20.75V17C22 16.3098 21.4402 15.75 20.75 15.75V8.25C21.4402 8.25 22 7.69023 22 7ZM18.25 4.5H19.5V5.75H18.25V4.5ZM4.5 4.5H5.75V5.75H4.5V4.5ZM5.75 19.5H4.5V18.25H5.75V19.5ZM19.5 19.5H18.25V18.25H19.5V19.5ZM18.25 15.75H17C16.3098 15.75 15.75 16.3098 15.75 17V18.25H8.25V17C8.25 16.3098 7.69023 15.75 7 15.75H5.75V8.25H7C7.69023 8.25 8.25 7.69023 8.25 7V5.75H15.75V7C15.75 7.69023 16.3098 8.25 17 8.25H18.25V15.75Z' fill='%2316B5B8'/%3E%3C/svg%3E%0A");
}

.mapboxgl-ctrl-top-right {
  margin-top: 15px;
}

.mapboxgl-ctrl-top-right .mapboxgl-ctrl-group {
  margin: 0 20px 0 0;
  border-radius: 0 0 5px 5px;
  border-bottom: 1px solid #a5abb3;
}

.mapboxgl-ctrl-top-right .mapbox-ctrl-ruler {
  border-radius: 5px 5px 0 0;
}

.mapboxgl-ctrl-radius {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 2C6.47702 2 2 6.47702 2 12C2 17.523 6.47702 22 12 22C17.523 22 22 17.523 22 12C22 6.47702 17.523 2 12 2ZM12 19.4194C7.8996 19.4194 4.58065 16.1012 4.58065 12C4.58065 7.8996 7.89879 4.58065 12 4.58065C16.1004 4.58065 19.4194 7.89879 19.4194 12C19.4194 16.1004 16.1012 19.4194 12 19.4194ZM12 6.83871C9.1496 6.83871 6.83871 9.1496 6.83871 12C6.83871 14.8504 9.1496 17.1613 12 17.1613C14.8504 17.1613 17.1613 14.8504 17.1613 12C17.1613 9.1496 14.8504 6.83871 12 6.83871ZM12 14.5806C10.577 14.5806 9.41935 13.423 9.41935 12C9.41935 10.577 10.577 9.41935 12 9.41935C13.423 9.41935 14.5806 10.577 14.5806 12C14.5806 13.423 13.423 14.5806 12 14.5806Z' fill='%23A5ABB3'/%3E%3C/svg%3E%0A");
  background-position: 50%;
  background-repeat: no-repeat;
  display: block;
  height: 100%;
  width: 100%;
  padding: 8px;
}

.mapboxgl-ctrl-radius.active,
.mapbox-ctrl-ruler button.mapboxgl-ctrl-radius:hover {
  background-color: inherit;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 2C6.47702 2 2 6.47702 2 12C2 17.523 6.47702 22 12 22C17.523 22 22 17.523 22 12C22 6.47702 17.523 2 12 2ZM12 19.4194C7.8996 19.4194 4.58065 16.1012 4.58065 12C4.58065 7.8996 7.89879 4.58065 12 4.58065C16.1004 4.58065 19.4194 7.89879 19.4194 12C19.4194 16.1004 16.1012 19.4194 12 19.4194ZM12 6.83871C9.1496 6.83871 6.83871 9.1496 6.83871 12C6.83871 14.8504 9.1496 17.1613 12 17.1613C14.8504 17.1613 17.1613 14.8504 17.1613 12C17.1613 9.1496 14.8504 6.83871 12 6.83871ZM12 14.5806C10.577 14.5806 9.41935 13.423 9.41935 12C9.41935 10.577 10.577 9.41935 12 9.41935C13.423 9.41935 14.5806 10.577 14.5806 12C14.5806 13.423 13.423 14.5806 12 14.5806Z' fill='%2316B5B8'/%3E%3C/svg%3E%0A");
}

.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl.mapboxgl-ctrl-group {
  float: right;
  margin: 15px 20px 0 0;
}

.mapbox-ctrl-ruler button:not(.mapboxgl-ctrl-radius) {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.8889 7.55566H19.2222V10.6112C19.2222 10.7647 19.0979 10.889 18.9444 10.889H18.3889C18.2354 10.889 18.1111 10.7647 18.1111 10.6112V7.55566H15.8889V10.6112C15.8889 10.7647 15.7646 10.889 15.6111 10.889H15.0556C14.9021 10.889 14.7778 10.7647 14.7778 10.6112V7.55566H12.5556V10.6112C12.5556 10.7647 12.4313 10.889 12.2778 10.889H11.7222C11.5688 10.889 11.4444 10.7647 11.4444 10.6112V7.55566H9.22222V10.6112C9.22222 10.7647 9.09792 10.889 8.94444 10.889H8.38889C8.23542 10.889 8.11111 10.7647 8.11111 10.6112V7.55566H5.88889V10.6112C5.88889 10.7647 5.76458 10.889 5.61111 10.889H5.05556C4.90208 10.889 4.77778 10.7647 4.77778 10.6112V7.55566H3.11111C2.49757 7.55566 2 8.05323 2 8.66678V15.3334C2 15.947 2.49757 16.4446 3.11111 16.4446H20.8889C21.5024 16.4446 22 15.947 22 15.3334V8.66678C22 8.05323 21.5024 7.55566 20.8889 7.55566Z' fill='%23A5ABB3'/%3E%3C/svg%3E%0A");
  background-position: 50%;
  background-repeat: no-repeat;
  display: block;
  width: 100%;
  border-bottom: 1px solid #a5abb3;
}

.mapbox-ctrl-ruler button svg {
  display: none;
}

.mapbox-ctrl-ruler button.-active,
.mapbox-ctrl-ruler button:not(.mapboxgl-ctrl-radius):hover {
  background-color: inherit;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.8889 7.55554H19.2222V10.6111C19.2222 10.7646 19.0979 10.8889 18.9444 10.8889H18.3889C18.2354 10.8889 18.1111 10.7646 18.1111 10.6111V7.55554H15.8889V10.6111C15.8889 10.7646 15.7646 10.8889 15.6111 10.8889H15.0556C14.9021 10.8889 14.7778 10.7646 14.7778 10.6111V7.55554H12.5556V10.6111C12.5556 10.7646 12.4313 10.8889 12.2778 10.8889H11.7222C11.5688 10.8889 11.4444 10.7646 11.4444 10.6111V7.55554H9.22222V10.6111C9.22222 10.7646 9.09792 10.8889 8.94444 10.8889H8.38889C8.23542 10.8889 8.11111 10.7646 8.11111 10.6111V7.55554H5.88889V10.6111C5.88889 10.7646 5.76458 10.8889 5.61111 10.8889H5.05556C4.90208 10.8889 4.77778 10.7646 4.77778 10.6111V7.55554H3.11111C2.49757 7.55554 2 8.05311 2 8.66665V15.3333C2 15.9469 2.49757 16.4444 3.11111 16.4444H20.8889C21.5024 16.4444 22 15.9469 22 15.3333V8.66665C22 8.05311 21.5024 7.55554 20.8889 7.55554Z' fill='%2316B5B8'/%3E%3C/svg%3E%0A");
}

.mapboxgl-ctrl button.mapboxgl-ctrl-geolocate .mapboxgl-ctrl-icon:hover {
  background-color: white;
  background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 8.5C9.79 8.5 8 10.29 8 12.5C8 14.71 9.79 16.5 12 16.5C14.21 16.5 16 14.71 16 12.5C16 10.29 14.21 8.5 12 8.5ZM20.94 11.5C20.48 7.33 17.17 4.02 13 3.56V2.5C13 1.95 12.55 1.5 12 1.5C11.45 1.5 11 1.95 11 2.5V3.56C6.83 4.02 3.52 7.33 3.06 11.5H2C1.45 11.5 1 11.95 1 12.5C1 13.05 1.45 13.5 2 13.5H3.06C3.52 17.67 6.83 20.98 11 21.44V22.5C11 23.05 11.45 23.5 12 23.5C12.55 23.5 13 23.05 13 22.5V21.44C17.17 20.98 20.48 17.67 20.94 13.5H22C22.55 13.5 23 13.05 23 12.5C23 11.95 22.55 11.5 22 11.5H20.94ZM12 19.5C8.13 19.5 5 16.37 5 12.5C5 8.63 8.13 5.5 12 5.5C15.87 5.5 19 8.63 19 12.5C19 16.37 15.87 19.5 12 19.5Z' fill='%2316B5B8'/%3E%3C/svg%3E%0A");
}
